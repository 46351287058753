import React, { useState, useEffect, useRef } from "react";

const MarqueeitemTwo = (props) => {
  const [increment] = useState(1);
  const [loopDelay] = useState(500 / 20);
  const firstSlideRef = useRef(null);
  const marqueeRightRef = useRef(null);
  let loop;

  const destroyLoop = () => {
    clearInterval(loop);
  };

  const createLoop = () => {
    loop = setInterval(() => {
      const slides = marqueeRightRef.current.children;
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];
        const margin =
          i === 0
            ? parseInt(window.getComputedStyle(slide).marginRight) - increment
            : 0;
        slide.style.marginRight = `${margin}px`;
      }

      if (
        Math.abs(parseInt(window.getComputedStyle(slides[0]).marginRight)) >=
        slides[0].offsetWidth
      ) {
        slides[0].style.marginRight = "0";
        marqueeRightRef.current.appendChild(slides[0].cloneNode(true));
        marqueeRightRef.current.removeChild(slides[0]);
      }
    }, loopDelay);
  };

  const handleMouseEnter = () => {
    destroyLoop();
  };

  const handleMouseLeave = () => {
    createLoop();
  };

  useEffect(() => {
    createLoop();
    return () => destroyLoop();
  }, []);

  const { pClass } = props;
  const sliderData = [
    {
      id: 1,
      title: "*Wordpress Customisation",
    },
    {
      id: 2,
      title: "*Dashboard Front-end",
    },
    {
      id: 3,
      title: "*Landing page Design",
    },
    {
      id: 4,
      title: "Mobile Application",
    },
    {
      id: 5,
      title: "Mobile Application",
    },
    {
      id: 6,
      title: "*Landing page Design",
    },
    {
      id: 7,
      title: "Mobile Application",
    },
    {
      id: 8,
      title: "*Mobile Application",
    },
    // Add more items as needed
  ];

  return (
    <div
      className={`marquee_left ${pClass}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={marqueeRightRef}
    >
      {sliderData.map((item, index) => (
        <div key={item.id} className="slide">
          <div className="item" ref={index === 0 ? firstSlideRef : null}>
            <h3>{item.title}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MarqueeitemTwo;
