import React, { useEffect, useState } from "react";

const HeightEmulator = () => {
  const [emulatorHeight, setEmulatorHeight] = useState(0);

  useEffect(() => {
    const updateEmulatorHeight = () => {
      const footerHeight =
        document.querySelector(".vlt-footer--fixed").offsetHeight;
      setEmulatorHeight(footerHeight);
    };

    updateEmulatorHeight(); // Initial setup

    window.addEventListener("resize", updateEmulatorHeight);

    return () => {
      window.removeEventListener("resize", updateEmulatorHeight);
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="height-emulator" style={{ height: emulatorHeight }}></div>
  );
};

export default HeightEmulator;
