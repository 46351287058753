import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

class FooterThree extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="footer-area-three vlt-footer--fixed">
        <div className="footer_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-6">
                <div className="about_footer">
                  <Link
                    to="/"
                    className="logo wow fadeInDown"
                    data-wow-delay="0.4s"
                  >
                    <img src={require("../image/logo2.png")} alt="" />
                  </Link>
                  <p>
                    This is the team that specializes in making sure in the find
                    it a into the find to make ita into the find to make it
                    amazing to end. and amke it to be your interior find to all
                    off users looks cool end.
                  </p>
                  <Fade direction="up" cascade triggerOnce>
                    <ul className="list_style">
                      {jhonData.socialLinks.map((item) => {
                        return (
                          <li key={item.name}>
                            <a href={item.url}>
                              <i className={item.className}></i>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </Fade>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-6">
                <ul className="list-unstyled footer_three_list">
                  <li>
                    <a href="#">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Mobile App</a>
                  </li>
                  <li>
                    <a href="#">SaaS Design</a>
                  </li>
                  <li>
                    <a href="#">Digital Design</a>
                  </li>
                  <li>
                    <a href="#">Design System Service</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-sm-3 col-6">
                <ul className="list-unstyled footer_three_list">
                  <li>
                    <a href="#">Professional</a>
                  </li>
                  <li>
                    <a href="#">Live Booking</a>
                  </li>
                  <li>
                    <a href="#">Testimonial</a>
                  </li>
                  <li>
                    <a href="#">My Team</a>
                  </li>
                  <li>
                    <a href="#">Carrer </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom_three text-center">
          <p>© 2024 Blazethemez - All Rights Reserved</p>
        </div>
      </section>
    );
  }
}

export default FooterThree;
